@import '../../../styles/_variables.scss';

.c_mstatus-contributors {
	&__heading {
		font-size: 1.223em;

		@media (max-width: $small-screen-max) {
			margin-top: 3em;
		}

		@media (min-width: $large-screen-min) {
			font-size: 1.445em;
		}
	}

	&__inner-container {
		border-top: 1px solid #CCC;
		padding: 0.5em 0 1em 0;

		@media (min-width: $large-screen-min) {
			padding: 2em 0 2.5em 0;
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			align-items: flex-start;
		}
	}

	&__item {
		display: flex;
		margin: 0.5em 0;

		@media (min-width: $large-screen-min) {
			display: inline-block;
			flex-wrap: wrap;
			text-align: center;
			width: 16%;
			margin: 0 .2em 1em 0;
		}

		img {
			width: 1.75em;
			height: 1.75em;
			display: block;

			@media (min-width: $large-screen-min) {
				margin: 0 auto;
				width: 7em;
    			height: 3.5em;
			}
		}
	}

	&__item-text {
		margin: auto 0.5em;
		font-size: 0.889em;

		@media (min-width: $large-screen-min) {
			display: block;
			margin: 1em 0 0 0;
			word-break: break-word;
			font-size: 0.8em;
		}
	}
}
