@import '../../../styles/_variables.scss';
@import '../../../styles/_colors.scss';

.c_text-area {
	position: relative;
	width: 100%;
	max-width: none;
	display: inline-block;
	margin-top: 1.5em;

	@media (min-width: $large-screen-min) {
		margin-top: 2.1em;
	}

	.c_text-area__label-text, .c_text-area__invalid-text {
		line-height: 1;
		display: block;
		width: 100%;
		margin-bottom: 0.5em;
		font-family: $base-font-family;
	}

	.c_text-area__invalid-text {
		color: $color--error-red;
		font-size: 0.805em;
	}

	textarea {
		display: block;
		appearance: none;
		-webkit-appearance: none;
		background-color: $color--bright-white;
		border: 1px solid $color--input-border-grey;
		border-radius: 0;
		font: {
			family: $base-font-family;
			size: 1em;
		}
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		min-height: 8em;
		padding: 0.7em 0.8em;
		box-sizing: border-box;

		@media (min-width: $large-screen-min) {
			padding: 0.8em;
		}
	}

	&.c_text-area--invalid {
		margin-top: 1.6em;

		@media (min-width: $large-screen-min) {
			margin-top: 0.8em;
		}

		textarea {
			border-color: $color--error-red;
		}
	}
}