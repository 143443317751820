$color--petrol: #00615C;
$color--petrol--hover: #00433F;
$color--cyprus: #004340;
$color--petrol--light: #638886;
$color--persian: #009b96;
//$color--temp:     #638886;
$color--geyser: #D0E3E2;
$color--pale-grey: #ebf3f3;
$color--silver: #d9e4e4;
$color--white: #f0f0f0;
$color--white--ish:#F5F5F5;
$color--white--gray-ish:#F8F8F8;
$color--bright-white: #ffffff;
$color--background-white: #fefefe;
$color--black: #222222;
$color--concrete: #F8F8F8;
$color--concrete-dark: #DADADA;
$color--input-border-grey: #8A8A8A;
$color--warm-grey: #777777;
$color--warmer-grey: #707070;
$color--emperor: #4F4F4F;
$color--pinkish-grey: #cccccc;
$color--tripleA-grey: #575757;
$color--pumpkin: #ef7d00;
$color--pumpkin-20: rgba(#ef7d00, 0.2);
$color--pumpkin-30: rgba(#ef7d00, 0.3);
$color--dawn-pink: #FAF4F4;
$color--dark-blue-green: #004b46;
$color--dark-blue-green-10: rgba(#004b46, 0.1);
$color--error-red: #ca0000;
$color--dusty-gray: #999999;
$color--text-disabled: #555;

// Extra colors for dataviz
$color--sycamore: #8B9742;
$color--buddha-gold: #D4A504;
$color--coral-tree: #A57082;
$color--matisse: #1961A2;
$color--steel-blue: #5393C3;
$color--mariner: #3285CE;
$color--fun-green: #008B2B;

:export {
  petrol: $color--petrol;
  petrolHover: $color--petrol--hover;
  persian: $color--persian;
  geyser: $color--geyser;
  paleGrey: $color--pale-grey;
  silver: $color--silver;
  white: $color--white;
  brightWhite: $color--bright-white;
  backgroundWhite: $color--background-white;
  black: $color--black;
  warmGrey: $color--warm-grey;
  pinkishGrey: $color--pinkish-grey;
  pumpkin: $color--pumpkin;
  pumpkin20: $color--pumpkin-20;
  pumpkin30: $color--pumpkin-30;
  darkBlueGreen: $color--dark-blue-green;
  darkBlueGreen10: $color--dark-blue-green-10;
  sycamore: $color--sycamore;
  buddhaGold: $color--buddha-gold;
  coralTree: $color--coral-tree;
  matisse: $color--matisse;
  steelBlue: $color--steel-blue;
  funGreen: $color--fun-green;
}
