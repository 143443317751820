@import '../../../styles/_variables.scss';
@import '../../../styles/_colors.scss';
@import '../../../styles/_colorsMiljostatus.scss';
@import '../../../styles/_mixins.scss';

.c_master-footer {
	position: relative;
	width: 100%;
	z-index: 1;

	&__address-header {
		margin: 0;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		span {
			display: inline-block;
			font-size: 1em;
			line-height: 1.5em;
			margin: 0;
			font-family: $medium-font-family;
		}
	}

	&__logo {
		width: 7em;
		height: 2.4em;
		max-width: 180px;
		margin-bottom: 1em;

		@media (min-width: $large-screen-min) {
			margin-top: 0.5em;
			margin-bottom: 0;
		}
	}

	&__links-wrapper,
	&__privacy-row {
		ul {
			display: block;
			overflow: hidden;
			margin-top: 1.2em;
			margin-left: -1em;
			margin-bottom: 0;

			@media (min-width: $large-screen-min) {
				margin-top: 1.2em;
				margin-left: -1em;
				margin-bottom: 0;
			}

			li {
				border-bottom: 1px solid rgba($color--bright-white, 0.25);
				padding: 1em 0;

				@media (min-width: $large-screen-min) {
					display: block;
					float: left;
					border-bottom: none;
					border-right: 1px solid $color--bright-white;
					padding: 0 1em 0 1em;

					&:last-of-type {
						border-right: none;
					}
				}
			}
		}
	}

	&__mail-phone-wrapper {
		@media (min-width: $large-screen-min) {
			margin-bottom: 0;
		}
	}

	&__email {
		display: inline-block;
	}

	&__mail-phone-wrapper,
	&__address-wrapper,
	&__social-wrapper {
		font-size: 0.905em;
		line-height: 1.5em;
		margin-bottom: 1.5em;

		address {
			font-style: normal;
		}
	}

	&__social-wrapper {
		ul {
			column-count: 2;
			column-gap: 10px;

			li {
				column-break-inside: avoid;
				-webkit-column-break-inside: avoid;
				font-size: 1em;
			}
		}
	}

	&__privacy-row {
		border-top: 1px solid rgba($color--bright-white, 0.12);
		margin-bottom: 0 !important;
		padding-top: 1em;

		ul {
			margin-top: 0;
			margin-right: 0;
			margin-bottom: 0;

			@media (min-width: $large-screen-min) {
				margin-left: -0.8em !important;
			}

			li {
				font-size: 0.905em;
			}
		}
	}

	&__link-list {
		&--main {
			@media (min-width: $large-screen-min) {
				margin-top: 1.2em !important;
				margin-left: -1em !important;
				margin-bottom: 0px !important;
			}
		}
	}
}

.c_master-footer-content {
	width: 100%;
	background-color: $color-miljostatus--dark-blue-1;
	color: $color--bright-white;
	padding: 40px 0;
	margin: 0;

	@media (min-width: $large-screen-min) {
		padding-bottom: 0.9em;
	}

	* {
		&:focus,
		&:active {
			outline-color: white;
		}
	}

	.g_row {
		@media (min-width: $large-screen-min) {
			margin-bottom: 2em;
			overflow: visible;
			display: inline-block;
		}
	}

	.g_container {
		@media (max-width: $small-screen-max) {
			padding: 0 ($container-padding * 3);
		}
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	a {
		font-family: $base-font-family;
		color: $color--bright-white;
		text-decoration: underline;

		@include hover() {
			color: $color--bright-white;
		}
		&:focus {
			text-decoration: underline;
		}
	}

	.c_language-select {
		position: relative;
		height: 2.5em;
		width: 100%;
		max-width: none;
		background: transparent;
		display: inline-block;
		margin-top: 2em;
		margin-bottom: 1.35em;

		@media (min-width: $large-screen-min) {
			font-size: 1em;
			max-width: 440px;
			margin-top: 0.63em;
			margin-bottom: 0;
		}

		&__arrow-wrapper {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 0.4em;
			width: 2.5em;
			height: 2.3em;
			padding-left: 0.5em;
			pointer-events: none;
		}

		img {
			position: absolute;
			top: 50%;
			transform: translateY(-50%) rotate(90deg);
			right: 0.8em;
			width: 0.4em;
			height: 0.8em;
		}

		select {
			appearance: none;
			-webkit-appearance: none;
			position: absolute;
			top: 0;
			left: 0;
			background: transparent;
			border: 1px solid #FFF;
			border-radius: 0;
			color: #FFF;
			font: {
				family: $base-font-family;
				size: 1em;
			}
			height: 2.5em;
			width: 100%;
			padding: 0 0.8em;

			&::-ms-expand {
				display: none;
			}

			@media (min-width: $large-screen-min) {
				padding: 0 0.8em;
			}

			&:focus {
				outline-offset: $focus-outline-offset;
				outline-width: $focus-outline-width;
			}

			option {
				color: $color--black;

				&:disabled {
					color: $color--text-disabled;
				}
			}
		}
	}
}
