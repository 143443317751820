@import '../../../styles/_variables.scss';
@import '../../../styles/_colors.scss';

.c_text-input {
	position: relative;
	width: 100%;
	max-width: none;
	display: inline-block;
	margin-top: 2em;

	.c_text-input__label-text, .c_text-input__invalid-text {
		line-height: 1;
		display: block;
		margin-bottom: 0.5em;
		font-family: $base-font-family;
	}

	.c_text-input__invalid-text {
		color: $color--error-red;
		font-size: 0.805em;
	}

	input {
		appearance: none;
		-webkit-appearance: none;
		background-color: $color--bright-white;
		border: 1px solid $color--input-border-grey;
		border-radius: 0;
		font: {
			family: $base-font-family;
			size: 1em;
		}
		height: 2.5em;
		width: 100%;
		padding: 0 0.8em;
		box-sizing: border-box;

		@media (min-width: $large-screen-min) {
			height: 3em;
			padding: 0 0.8em;
		}
	}

	&.c_text-input--invalid {
		margin-top: 1.6em;

		@media (min-width: $large-screen-min) {
			margin-top: 0.8em;
		}

		input {
			border-color: $color--error-red;
		}
	}
}