@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';
@import '../../styles/_colors.scss';
@import '../../styles/_colorsMiljostatus.scss';

.c_feedback-bar {
	background-color: $color-miljostatus--grey-blue-2;
	width: 100%;

	.g_container {
		@media (max-width: $small-screen-max) {
			padding: 0 ($container-padding * 3);
		}
	}

	.c_feedback-bar-header {
		padding: 1.5em 0 1.45em 0;
		font-size: 1.112em;

		.g_container--full {
			min-height: 32px;
			line-height: 32px;
		}

		.c_feedback-bar-header__question {
			display: block;
			width: 100%;
			margin-bottom: 1em;

			@media (min-width: $large-screen-min) {
				display: inline;
				width: auto;
				margin-right: 2em;
				margin-bottom: 0;
			}
		}

		.c_feedback-bar-header__buttons-wrapper {
			.c_feedback-bar-header__button {
				@include removeButtonAppearance();
				font-family: $base-font-family;
				font-size: 1em;
				padding: 0.25em 0.5em;

				@include hover() {
					text-decoration: underline;
				}

				&:focus {
					background-color: rgba($color--background-white, 0.5);
				}
				
				&--clicked {
					text-decoration: underline;
				}
			}
		}

		.c_loading-spinner {
			display: inline-block;
			height: 0.9em;
			width: 0.9em;
			margin: 0 0 0 0.5em;

			img {
				width: 1.1em;
				height: 1.1em;
			}
		}

		.c_feedback-bar-header__type-sent-confirmation {
			font-family: $light-italic-font-family;
			@include fadeInAnimation();

			img {
				width: 0.6em;
				height: 0.7em;
				margin-left: 1.5em;
				margin-right: -0.3em;
			}

			span {
				margin-left: 0.8em;
			}
		}
	}

	.c_feedback-bar-content {
		display: block;
		overflow: hidden;
		transition: all 0.7s ease;

		&--expanded {
			border-top: 1px solid #A5C7C6;
		}

		.c_close-button {
			@include removeButtonAppearance();
			font: {
				family: $light-italic-font-family;
				size: 0.845em;
			}
			float: right;
			margin-top: 1.5em;
			position: absolute;
			right: 30px;

			&:focus {
				background-color: rgba($color--background-white, 0.5);
			}

			.c_close-button__inner-wrapper {
				float: right;

				.c_close-button__text {
					display: inline-block;
					margin-top: -0.12em;
				}

				img {
					height: 0.88em;
					width: 0.88em;
					margin-left: 0.88em;
				}
			}

			@include hover() {
				.c_close-button__text {
					text-decoration: underline;
				}
			}
		}

		h2 {
			margin-top: 0;
		}

		.c_text-area {
			margin-top: 0;

			textarea {
				@media (min-width: $large-screen-min) {
					min-height: 16em;
				}
			}
		}

		.c_feedback-bar-content__right-col {
			width: 100%;

			@media (min-width: $large-screen-min) {
				max-width: 383px;
			}

			p {
				margin-bottom: 0;

				@media (min-width: $large-screen-min) {
					margin: 0;
				}
			}
		}

		.c_button {
			margin: 1.6em auto 0 0;
		}
	}

	.c_feedback-bar-content-container {
		overflow: hidden;
		box-sizing: border-box;
		padding-top: 1.5em;
		padding-bottom: 3em;

		@media (min-width: $large-screen-min) {
			padding-top: 3em;
		}

		.c_loading-spinner {
			margin: 7.5em auto;

			img {
				width: 65px;
				height: 65px;

				@media (min-width: $large-screen-min) {
					width: 80px;
					height: 80px;
				}
			}
		}
	}
}