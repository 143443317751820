// Font related
$base-font-size: 1.125em;
$base-font-family: 'PalatinoLTStd-Roman', Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
$base-italic-font-family: 'PalatinoLTStd-Italic', Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
$light-font-family: 'PalatinoLTStd-Light', Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
$light-italic-font-family: 'PalatinoLTStd-LightItalic', Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
$medium-font-family: 'PalatinoLTStd-Medium', Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;

// Margins & paddings
$container-padding: 10px;

// Media queries/screen sizes
$extra-small-screen-max: 420px;
$small-screen-max: 768px;
$large-screen-min: 769px;
$xl-screen-min: 1100px;

// Grid sizes
$container-small-maxwidth: 680px + ($container-padding * 2);
$container-medium-maxwidth: 780px + ($container-padding * 2);
$container-full-maxwidth: 1070px + ($container-padding * 2);

// Forus settings
$focus-outline-width: 2px;
$focus-outline-offset: 3px;
$focus-outline-color: #BD6B00;

:export {
	fontFamily: $base-font-family;
}