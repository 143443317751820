@import '../../../styles/_variables.scss';
@import '../../../styles/_colors.scss';
@import '../../../styles/_colorsMiljostatus.scss';
@import '../../../styles/_mixins.scss';

.c_button {
	@include removeButtonAppearance();
	box-sizing: border-box;
	background-color: $color-miljostatus--dark-blue-1;
	color: $color--bright-white;
	display: block;
	font: {
		family: $base-font-family !important;
		size: 1em;
	}
	margin-bottom: 2em;
	max-width: 100%;
	padding: 0.675em 1.5em 0.6em 1.5em;
	min-height: 44px;
	@include transitionStandard();

	@media print {
		background-color: transparent;
		color: $color-miljostatus--dark-blue-1;
		border: 1px solid $color-miljostatus--grey-blue-4;
	}

	@include hover() {
		background-color: $color-miljostatus--dark-blue-1--hover;
		text-decoration: underline;
	}

	&--light {
		background-color: $color-miljostatus--grey-blue-2;
		color: $color--black;
		border: 1px solid $color-miljostatus--dark-blue-1;

		@include hover() {
			background-color: $color-miljostatus--grey-blue-3;
		}
	}

	&--disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	&__left-icon {
		width: 0.75em;
		height: 0.75em;
		margin-right: 0.5em;
	}

	&__arrow {
		height: 1em;
		width: 0.4em;
		display: inline-block;
		float: right;
		margin-left: 2em;
	}
}

a.c_button {
	align-items: flex-start;
	display: inline-block;
	text-align: center;
	text-decoration: none;

	&:hover {
		color: $color--bright-white;
		text-decoration: underline;
	}
}
