// Miljostatus colors
$color-miljostatus--dark-blue-1: #1E4F71;
$color-miljostatus--dark-blue-1--hover: #19384E;
$color-miljostatus--dark-blue-2: #19384E;
$color-miljostatus--dark-blue-3: #1B4D71;
$color-miljostatus--dark-blue-4: #36556A;
$color-miljostatus--grey-blue-1: #F5F8FB;
$color-miljostatus--grey-blue-2: #ECF2F7;
$color-miljostatus--grey-blue-3: #D6E1E8;
$color-miljostatus--grey-blue-4: #C0D1DD;
$color-miljostatus--grey-blue-5: #B8BEC1;
$color-miljostatus--grey-blue-6: #B8CAD5;
$color-miljostatus--focus-orange: #BD6B00;
$color-miljostatus--black: #222222;
$color-miljostatus--light-grey: #CCCCCC;
$color-miljostatus--menu-border-grey: #82888B;
$color-miljostatus--menu-hover: #C2D1DB;

$color-miljostatus--background-white: #fefefe;
$color-miljostatus--pale-grey: #ebf3f3;
$color-miljostatus--pale-grey-2: #f6f9fb;

$color-miljostatus--border-blue: rgba(30, 79, 113, .25);

$color-miljostatus--pale-yellow-1: #F4F0E4;
$color-miljostatus--pale-yellow-2: #FCFEF7;

$color--search-hightlight: #ECD898;
